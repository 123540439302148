import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Components
import AppBar from "../../components/marketplace/AppBar";
// import Header from "../../components/Header";
import PropertyDetail from "../../components/marketplace/auction/Detail";
// import HistorySummary from "../../components/surveyor/HistorySummary";

function AuctionDetail() {
  return (
    <Container
      component="main"
      sx={{
        paddingTop: "2%",
        paddingBottom: "2%",
      }}
    >
      <AppBar />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <PropertyDetail />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AuctionDetail;
