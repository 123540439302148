import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Marketplace
import Marketplace from "./pages/marketplace/Index";
import MarketplaceDetail from "./pages/marketplace/Detail";
import MarketplaceAuction from "./pages/marketplace/Auction";
import MarketplaceAuctionDetail from "./pages/marketplace/AuctionDetail";

// Components
import Footer from "./components/Footer";

const theme = createTheme({
  typography: {
    fontFamily: `"Montserrat", sans-serif`,
    fontWeightRegular: 400,
    fontWeightBold: 600,
    letterSpacing: -1,
  },
  palette: {
    primary: {
      main: "#003E69",
    },
  },
});

export default function BasicTabs() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Marketplace />} exact></Route>
          <Route path="/detail" element={<MarketplaceDetail />}></Route>
          <Route path="/auction" element={<MarketplaceAuction />}></Route>
          <Route
            path="/auction/detail"
            element={<MarketplaceAuctionDetail />}
          ></Route>
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}
