import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function FilterForm() {
  return (
    <Grid container spacing={0.5} sx={{ pt: 2, pb: 4 }}>
      <Grid
        item
        xs={12}
        sx={{ background: "rgba(0, 62, 105, 0.05)", borderRadius: 2 }}
      >
        <Grid container spacing={3} sx={{ p: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Lokasi"
              defaultValue="Jakarta"
              variant="outlined"
              InputLabelProps={{
                style: { fontWeight: "bold", color: "#003E69" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Penawaran Kepemilikan"
              defaultValue="10%"
              variant="outlined"
              InputLabelProps={{
                style: { fontWeight: "bold", color: "#003E69" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ alignSelf: "center" }}>
            <Button
              fullWidth
              size="large"
              sx={{
                textTransform: "none",
                boxShadow: "unset",
                fontWeight: "bold",
              }}
            >
              Cari Tanah
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
