import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

function createData(name, buy, price) {
  return { name, buy, price };
}

const rows = [
  createData("Land 1", 15, "1.500.000.000"),
  createData("Land 1", 30, "3.000.000.000"),
];

const percentageColor = (value) => {
  return value > 15 ? "#276221" : "#ff0000";
};

const percentageIcon = (value) => {
  return value > 15 ? (
    <ArrowUpwardOutlinedIcon fontSize="small" />
  ) : (
    <ArrowDownwardOutlinedIcon fontSize="small" />
  );
};

export default function BasicTable() {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "unset" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0 }}>
              <b>Nama Property</b>
            </TableCell>
            <TableCell align="left" sx={{ pl: 0 }}>
              <b>Persentase Beli (%)</b>
            </TableCell>
            <TableCell align="right" sx={{ pl: 0 }}>
              <b>Harga (Rp)</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                {row.name}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: percentageColor(row.buy),
                  fontWeight: "bold",
                  pl: 0,
                  alignSelf: "center",
                }}
              >
                {row.buy}% {percentageIcon(row.buy)}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold", pl: 0 }}>
                {row.price}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
