import * as React from "react";
import AppBar from "@mui/material/AppBar";
// import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// Assets
import bursaTanahLogo from "../../assets/bursatanah.png";

export default function Drawer() {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        marginTop: 1,
        marginBottom: 3,
        background: "unset",
        borderBottom: "1px solid rgba(38, 38, 38, 0.1)",
      }}
    >
      <Toolbar
        style={{
          flexWrap: "wrap",
          padding: 0,
          paddingBottom: 1,
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Link href="/">
            <img src={bursaTanahLogo} alt="Bursa Tanah" width="200" />
          </Link>
        </div>
        <nav style={{ marginTop: 5, marginBottom: 15 }}>
          <Link
            variant="button"
            href="/"
            sx={{
              my: 1,
              mx: 1.5,
              textTransform: "none",
              color: "#003E69",
              textDecoration: "unset",
              marginLeft: 0,
            }}
          >
            Bursa Lelang
          </Link>
          <Link
            variant="button"
            href="/auction"
            sx={{
              my: 1,
              mx: 1.5,
              textTransform: "none",
              color: "#003E69",
              textDecoration: "unset",
            }}
          >
            Property Saya
          </Link>
        </nav>
      </Toolbar>
    </AppBar>
  );
}
