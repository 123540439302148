import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, sell, price) {
  return { name, sell, price };
}

const rows = [
  createData("Land 1", 30, "3.000.000.000"),
  createData("Land 2", 10, "1.000.000.000"),
  createData("Land 3", 5, "500.000.000"),
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "unset" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0 }}>
              <b>Nama Property</b>
            </TableCell>
            <TableCell align="left" sx={{ pl: 0 }}>
              <b>Persentase Jual (%)</b>
            </TableCell>
            <TableCell align="right" sx={{ pl: 0 }}>
              <b>Harga (Rp)</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                {row.name}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  pl: 0,
                }}
              >
                {row.sell}%
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  pl: 0,
                }}
              >
                {row.price}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
