import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

//
import Maps from "../Maps";
import Breadcrumbs from "../../Breadcrumbs";

// Assets
import Property1 from "../../../assets/property/property1.jpg";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

// Assets Whitepaper
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';

function WhitePaperCard({ ...props }) {
  const { data } = props;

  return (
    <Card
      sx={{
        boxShadow: "unset",
        background: "rgba(0, 62, 105, 0.05)",
        borderRadius: 2,
      }}
    >
      <CardContent>
        <data.icons sx={{ fontSize: 40, mb: 2 }} color="primary" />
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle2" sx={{ alignSelf: "center" }}>
            {data.text}
          </Typography>
        </div>
        <Typography
          variant="body2"
          fontWeight="bold"
          sx={{ alignSelf: "center", mt: 0.5 }}
        >
          {data.value}
        </Typography>
      </CardContent>
    </Card>
  );
}

function PropertyImage({ ...props }) {
  const { data } = props;

  return (
    <CardMedia
      sx={{ height: 350, borderRadius: 2 }}
      image={data.img}
      title="green iguana"
    />
  );
}

function PropertyDetail({ ...props }) {
  const { data } = props;

  const whitepaperData = [
    {
      id: 1,
      icons: StickyNote2OutlinedIcon,
      text: "PBB",
    },
    {
      id: 2,
      icons: ImageOutlinedIcon,
      text: "Sertifikat Tanah",
    },
    {
      id: 3,
      icons: CalculateOutlinedIcon,
      text: "Hasil Ukur Ulang",
    },
    {
      id: 4,
      icons: ArticleOutlinedIcon,
      text: "NIB",
      value: "00010",
    },
    {
      id: 5,
      icons: ChecklistOutlinedIcon,
      text: "Tipe Hak",
      value: "Hak Milik",
    },
    {
      id: 6,
      icons: GradingOutlinedIcon,
      text: "Luas Tertulis",
      value: "167 M2",
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          color="primary"
          sx={{ fontStyle: "italic" }}
        >
          Detail Tanah:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          fontWeight="bold"
          variant="h5"
          color="primary"
          sx={{ mb: 1 }}
        >
          {data.title}
        </Typography>
        <div style={{ display: "flex" }}>
          <PlaceOutlinedIcon />
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ alignSelf: "center", ml: 1 }}
          >
            {data.address}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography
          color="text.secondary"
          variant="subtitle2"
          sx={{ textAlign: "justify", lineHeight: 1.8 }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel
          neque hendrerit, ornare turpis vitae, dapibus lacus. Suspendisse
          congue turpis vitae pharetra dapibus. Vivamus feugiat ornare nibh.
          Praesent lacinia vel quam quis posuere. Nullam vel erat consequat,
          consequat est sit amet, posuere mauris. Donec neque urna, feugiat
          sagittis ante in, tempor ornare velit.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Grid container spacing={3}>
          {whitepaperData.map((x, i) => (
            <Grid item key={i} xs={12} md={4}>
              <WhitePaperCard data={x} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Maps />
      </Grid>
    </Grid>
  );
}

function PropertyOffersDetail() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          color="primary"
          sx={{ fontStyle: "italic" }}
        >
          Detail Penawaran:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="text.secondary">
          Penawaran Kepemilikan:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <div style={{ display: "flex" }}>
          <div style={{ alignSelf: "center" }}>
            <LocalOfferOutlinedIcon sx={{ fontSize: 30 }} color="primary" />
          </div>
          <Typography
            variant="h5"
            fontWeight="bold"
            color="primary"
            sx={{ alignSelf: "center", ml: 1 }}
          >
            30%
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <PropertyFormOffers />
      </Grid>
    </Grid>
  );
}

function PropertyFormOffers() {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 62, 105, 0.05)",
        p: 3,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            size="small"
            label="Penawaran Kepemilikan (%)"
            value={"30%"}
            InputLabelProps={{ style: { fontSize: 14, alignSelf: "center" } }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Harga (Rp)"
            value={"3.000.000.000"}
            InputLabelProps={{ style: { fontSize: 14, alignSelf: "center" } }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <hr style={{ borderColor: "#0000000f" }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Total Harga (Rp)"
            value={"3.000.000.000"}
            InputLabelProps={{ style: { fontSize: 14, alignSelf: "center" } }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Button
            fullWidth
            variant="contained"
            style={{
              textTransform: "none",
            }}
          >
            Update Detail Penawaran
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function ActionAreaCard() {
  const data = {
    id: 1,
    img: Property1,
    title: "Land 1",
    address: "Jl. Bendungan Hilir, Jakarta",
    ownershipPercentage: "30%",
    percentage: "30%",
    divisibility: "3",
  };

  return (
    <Grid container spacing={4} sx={{ pt: 1, pb: 5 }}>
      <Grid item xs={12} md={12}>
        <Breadcrumbs title={"Land List"} link={"/auction"} />
      </Grid>
      <Grid item xs={12} md={12}>
        <PropertyImage data={data} />
      </Grid>
      <Grid item xs={12} md={8}>
        <PropertyDetail data={data} />
      </Grid>
      <Grid item xs={12} md={4}>
        <PropertyOffersDetail data={data} />
      </Grid>
    </Grid>
  );
}
