import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

// Assets
import Property1 from "../../assets/property/property1.jpg";
import Property2 from "../../assets/property/property2.jpg";
import Property3 from "../../assets/property/property3.jpg";
import Property4 from "../../assets/property/property5.jpg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

function AuctionCard({ ...props }) {
  const { data } = props;

  return (
    <Card
      sx={{
        boxShadow: "unset",
      }}
    >
      <CardMedia
        sx={{ height: 180, borderRadius: 2 }}
        image={data.img}
        title="green iguana"
      />
      <CardContent sx={{ padding: 0, pt: 2 }}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 0.5 }}>
            <Typography
              fontWeight="bold"
              variant="subtitle1"
              color="primary"
              sx={{ mb: 0.8 }}
            >
              {data.title}
            </Typography>
            <div style={{ display: "flex" }}>
              <LocationOnOutlinedIcon size="small" color="primary" />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ alignSelf: "center", ml: 0.5 }}
              >
                {data.address}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, mb: 1.5 }}>
            <LinearProgress
              color="primary"
              variant="determinate"
              value={data.valuePercentage}
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              textAlign="left"
            >
              Penawaran Kepemilikan:{" "}
              <b style={{ color: "#003E69" }}>{data.ownershipPercentage}</b>
            </Typography>
          </Grid>
          <Grid item xs={5} md={5}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              textAlign="right"
            >
              Penawar: <b style={{ color: "#003E69" }}>{data.bidder}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <hr style={{ borderColor: "#ffffff4d" }} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ padding: 0 }}>
        <Button
          size="big"
          component={Link}
          sx={{
            color: "#003E69",
            textTransform: "none",
            fontWeight: "bold",
            paddingLeft: 0,
          }}
          to="/detail"
        >
          Buat Penawaran
        </Button>
      </CardActions>
    </Card>
  );
}

export default function ActionAreaCard() {
  const data = [
    {
      id: 1,
      img: Property1,
      title: "Land 1",
      address: "Jl. Bendungan Hilir, Jakarta",
      ownershipPercentage: "30%",
      valuePercentage: 30,
      ownerId: "A12345",
      ownerName: "Manajer Property 1",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 2,
    },
    {
      id: 2,
      img: Property3,
      title: "Land 2",
      address: "Jl. Toddopuli X No 11, Makassar",
      ownershipPercentage: "10%",
      valuePercentage: 10,
      ownerId: "A76589",
      ownerName: "Manajer Property 1",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 1,
    },
    {
      id: 3,
      img: Property2,
      title: "Land 3",
      address: "Jl. Lobi-Lobi II/19, Bandung",
      ownershipPercentage: "5%",
      valuePercentage: 5,
      ownerId: "A12345",
      ownerName: "Manajer Property 1",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 2,
    },
    {
      id: 4,
      img: Property4,
      title: "Land 4",
      address: "Jl. Lobi-Lobi I/19, Bandung",
      ownershipPercentage: "10%",
      valuePercentage: 10,
      ownerId: "A76589",
      ownerName: "Manajer Property 2",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 2,
    },
  ];

  return (
    <Grid container spacing={0.5} sx={{ pt: 1 }}>
      <Grid item xs={12} md={12} sx={{ pb: 2 }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ mb: 0.5 }}
          color="primary"
        >
          Bursa Lelang
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          List Bursa Tanah Terkini
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={5} sx={{ pt: 2 }}>
          {data.map((x, i) => (
            <Grid item key={i} xs={12} md={4}>
              <AuctionCard data={x} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
