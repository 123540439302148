import * as React from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  FeatureGroup,
} from "react-leaflet";
import Grid from "@mui/material/Grid";

function Maps() {
  const location = [
    [-6.87028348077494, 107.618770407073],
    [-6.87020444298901, 107.618534025791],
    [-6.87038450623455, 107.618473748249],
    [-6.87038863713335, 107.618486102675],
    [-6.87041607534219, 107.618476917523],
    [-6.87044351285208, 107.618591368094],
    [-6.87048124168309, 107.618704205206],
    [-6.87028348077494, 107.618770407073],
  ];

  const redColor = { color: "red" };
  const center = [-6.905977, 107.613144];

  return (
    <Grid container spacing={0.5} sx={{ pt: 1 }}>
      <Grid item xs={12} md={12}>
        <MapContainer
          center={location[0] || center}
          zoom={25}
          scrollWheelZoom={false}
          style={{
            width: "100%",
            height: "25vh",
          }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <FeatureGroup pathOptions={redColor}>
            {/* <Rectangle bounds={location} /> */}
            <Polyline pathOptions={redColor} positions={location} />
          </FeatureGroup>
        </MapContainer>
      </Grid>
    </Grid>
  );
}

export default Maps;
