import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Components
import AppBar from "../../components/marketplace/AppBar";
import PropertyList from "../../components/marketplace/Property";
import Filter from "../../components/marketplace/Filter";
// import HistorySummary from "../../components/surveyor/HistorySummary";

// Assets
import Header from "../../assets/header.jpg";

function GenerateData() {
  return (
    <Container
      component="main"
      sx={{
        paddingTop: "2%",
        paddingBottom: "6%",
      }}
    >
      <AppBar />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2, mt: 2.5 }}>
          <img src={Header} alt="Bursa Tanah" width="100%" />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Filter />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <PropertyList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default GenerateData;
