import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

//
import OrderBookBuy from "../orderbook/Buy";
import OrderBookSell from "../orderbook/Sell";

// Assets
import Property1 from "../../../assets/property/property1.jpg";
import Property2 from "../../../assets/property/property2.jpg";
import Property3 from "../../../assets/property/property3.jpg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const transaction = [
  {
    id: 1,
    title: "Properti 1",
    address: "JL.Lobi-Lobi I/19",
    ownerName: "Ginung Pratidina",
    price: "Rp. 1.500.000.000",
    date: "11/05/2023",
    percentage: "15%",
  },
  {
    id: 2,
    title: "Properti 1",
    address: "JL.Lobi-Lobi I/19",
    ownerName: "Aga Subagja",
    price: "Rp. 3.000.000.000",
    date: "08/05/2023",
    percentage: "30%",
  },
];

function AuctionCard({ ...props }) {
  const { data } = props;

  return (
    <Card
      sx={{
        boxShadow: "unset",
      }}
    >
      <CardMedia
        sx={{ height: 180, borderRadius: 2 }}
        image={data.img}
        title="green iguana"
      />
      <CardContent sx={{ padding: 0, pt: 2 }}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 0.5 }}>
            <Typography
              fontWeight="bold"
              variant="subtitle1"
              color="primary"
              sx={{ mb: 0.8 }}
            >
              {data.title}
            </Typography>
            <div style={{ display: "flex" }}>
              <LocationOnOutlinedIcon size="small" color="primary" />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ alignSelf: "center", ml: 0.5 }}
              >
                {data.address}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, mb: 1.5 }}>
            <LinearProgress
              color="primary"
              variant="determinate"
              value={data.valuePercentage}
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              textAlign="left"
            >
              Penawaran Kepemilikan:{" "}
              <b style={{ color: "#003E69" }}>{data.ownershipPercentage}</b>
            </Typography>
          </Grid>
          <Grid item xs={5} md={5}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              textAlign="right"
            >
              Penawar: <b style={{ color: "#003E69" }}>{data.bidder}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <hr style={{ borderColor: "#ffffff4d" }} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ padding: 0 }}>
        <Button
          size="big"
          component={Link}
          sx={{
            color: "#003E69",
            textTransform: "none",
            fontWeight: "bold",
            paddingLeft: 0,
          }}
          to="/auction/detail"
        >
          Update Data
        </Button>
      </CardActions>
    </Card>
  );
}

function TransactionHistory({ ...props }) {
  const { data } = props;

  return (
    <Card
      sx={{
        boxShadow: "unset",
        background: "rgba(0, 62, 105, 0.05)",
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, mt: 1 }}>
            <div style={{ display: "flex" }}>
              <AccountCircleOutlinedIcon
                sx={{ fontSize: 28 }}
                color="primary"
              />
              <Typography
                variant="subtitle1"
                color="primary"
                fontWeight="bold"
                sx={{ alignSelf: "center", ml: 1 }}
              >
                {data.ownerName}
              </Typography>
            </div>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Harga
              </Typography>
              <Typography variant="subtitle2" fontWeight="bold">
                {data.price}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Penawaran
              </Typography>
              <Typography variant="subtitle2" fontWeight="bold">
                {data.percentage}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Tanggal Penawaran
              </Typography>
              <Typography variant="subtitle2" fontWeight="bold">
                {data.date}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr style={{ borderColor: "#0000000f" }} />
            </Grid>
          </Grid>
        </Grid>
        <CardActions sx={{ padding: 0, mt: 0.5 }}>
          <Button
            size="big"
            component={Link}
            sx={{
              color: "#000",
              textTransform: "none",
              fontWeight: "bold",
              paddingLeft: 0,
            }}
            to="#"
          >
            Terima Tawaran
          </Button>
          <Button
            size="big"
            component={Link}
            sx={{
              color: "#000",
              textTransform: "none",
              fontWeight: "bold",
              paddingLeft: 0,
            }}
            to="#"
          >
            Hubungi Penawar
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

function AuctionHistory() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} sx={{ mt: 3 }}>
        <Typography variant="h6" color="primary" fontWeight="bold">
          Penawaran Anda:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Grid container spacing={4}>
          {transaction.map((x, i) => (
            <Grid item key={i} md={4} xs={12}>
              <TransactionHistory data={x} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sx={{ mt: 3 }}>
        <Typography variant="h6" color="primary" fontWeight="bold">
          Order Book:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <OrderBookSell />
          </Grid>
          <Grid item xs={12} md={6}>
            <OrderBookBuy />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function ActionAreaCard() {
  const data = [
    {
      id: 1,
      img: Property1,
      title: "Land 1",
      address: "JL.Lobi-Lobi I/19",
      ownershipPercentage: "30%",
      valuePercentage: 30,
      ownerId: "A12345",
      ownerName: "Manajer Property 1",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 2,
    },
    {
      id: 2,
      img: Property3,
      title: "Land 2",
      address: "JL.Toddopuli X No 11",
      ownershipPercentage: "10%",
      valuePercentage: 10,
      ownerId: "A76589",
      ownerName: "Manajer Property 1",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 1,
    },
    {
      id: 3,
      img: Property2,
      title: "Land 3",
      address: "JL.Lobi-Lobi II/19",
      ownershipPercentage: "5%",
      valuePercentage: 5,
      ownerId: "A12345",
      ownerName: "Manajer Property 1",
      percentage: "30%",
      propertyManajer: "JLL Indonesia",
      divisibility: "3",
      bidder: 2,
    },
  ];

  return (
    <Grid container spacing={2} sx={{ pt: 2, pb: 5 }}>
      <Grid item xs={12} md={9}>
        <Typography variant="h5" fontWeight="bold">
          Hi, Manajer Property 1 👋🏻
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{ alignSelf: "center", textAlign: "right" }}
      >
        <Button
          sx={{
            color: "primary",
            textTransform: "none",
            fontWeight: "bold",
          }}
          startIcon={
            <AddHomeOutlinedIcon style={{ fontSize: 30 }} color="primary" />
          }
        >
          Tambah Property
        </Button>
      </Grid>
      <Grid item xs={12} md={12} sx={{ mt: 3 }}>
        <Typography variant="h6" color="primary" fontWeight="bold">
          List Tanah Anda:
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} sx={{ mt: 2 }}>
        <Grid container spacing={5}>
          {data.map((x, i) => (
            <Grid item key={i} xs={12} md={4}>
              <AuctionCard data={x} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <AuctionHistory />
      </Grid>
    </Grid>
  );
}
